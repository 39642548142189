import { useState } from "react";

import GpuDrawer from "./GpuDrawer";

import styles from "./_server.module.scss";

export default function GpuInfo({ gpu }) {
  const [currentGpu, setCurrentGpu] = useState(null);

  return (
    <div>
      {gpu?.length ? (
        <div className={styles["gpu-list"]}>
          {gpu?.map((item, key) => {
            return (
              <div key={key} className={styles["gpu"]}>
                <div className={styles["gpu-button"]} onClick={() => setCurrentGpu(item)}>
                  <i className="fas fa-info-circle" />
                </div>

                <div className={styles["temperature"]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-thermometer-empty"></i>',
                    }}
                  />

                  {item.temperature.gpu_temp}
                </div>

                <p className={styles["memory"]}>Memory</p>

                <p className={styles["mib"]}>
                  {item.fb_memory_usage.total + "/" + item.fb_memory_usage.used}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles["single-gpu"]}>
          <div
            className={styles["gpu-button"]}
            onClick={() => {
              if (gpu) {
                setCurrentGpu(gpu);
              }
            }}
          >
            <i className="fas fa-info-circle" />
          </div>

          <div className={styles["temperature"]}>
            <div
              dangerouslySetInnerHTML={{
                __html: '<i class="fa fa-thermometer-empty"></i>',
              }}
            />

            {gpu?.temperature?.gpu_temp}
          </div>

          <p className={styles["memory"]}>Memory</p>

          <p className={styles["mib"]}>
            {gpu?.fb_memory_usage.total + "/" + gpu?.fb_memory_usage.used}
          </p>
        </div>
      )}

      <GpuDrawer
        gpu={currentGpu}
        open={currentGpu ? true : false}
        onClose={() => setCurrentGpu(null)}
      />
    </div>
  );
}
