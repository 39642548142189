import { Space, Tag, Tooltip } from "antd";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import en from "javascript-time-ago/locale/en"; // Import English locale for React Time Ago
import JavaScriptTimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";

import icons from "../../../config/icons";
import {
  getQueryDate,
  getColumnDateProps,
  getColumnSearchProps,
  parseDateFormat,
} from "../../../config/config";

import Check from "../../../_components/table/Check";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";

import {
  useUsersGroupsOptions,
  usePaymentsSubscriptionsOptions,
} from "../../../hooks/selectOptions";
import PATHS from "../../../config/paths";
import { useSelector } from "react-redux";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import UserBulkAction from "./UserBulkAction";
import TableEmpty from "../../../_components/table/TableEmpty";

let statusOptions = [
  { text: "Active", value: [0, 1] },
  { text: "Disabled by tariff", value: [2] },
  { text: "Disabled by admin", value: [3] },
];

const userStatusOptions = [
  { text: "Guest", value: true },
  { text: "Registered", value: false },
];

JavaScriptTimeAgo.addLocale(en);

export default function UsersTable({
  getAgain,
  openDrawer,
  openLoginDrawer,
  getDataSource,
  handleMenuClick,
  openMultiUserDrawer,
}) {
  const { permissions } = useSelector((state) => state.globalState);

  const navigate = useNavigate();
  const groupsOptions = useUsersGroupsOptions("text");

  const tariffOptions = usePaymentsSubscriptionsOptions("text");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_users") || 10);

  const [currentPage, setCurrentPage] = useState(getAtSession("users")?.page || 1);

  const [sort, setSort] = useState(getAtSession("users")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [registrationDate, setRegistrationDate] = useState(null);

  const [tariffCreatedDate, setTariffCreatedDate] = useState(null);

  const [expireTariffDate, setExpireTariffDate] = useState(null);

  const [search, setSearch] = useState(
    // getAtSession("users")
    getAtSession("users")?.search || {
      name: null,
      surname: null,
      phone: null,
      email: null,
      username: null,
    }
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [tableColumns, setTableColumns] = useState([]);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      width: 130,
      title: "Online / Offline",
      defaultSortOrder:
        getAtSession("users")?.sort?.[0] === "online" &&
        asc_desc(getAtSession("users")?.sort?.[1]),
      dataIndex: `online`,
      align: "center",
      key: "online",
      sorter: true,
      render: (text, record, index) => {
        return (
          <>
            {record.online ? (
              <Tag color="success">Online</Tag>
            ) : (
              <Tooltip
                title={() => {
                  return record?.last_online ? (
                    <ReactTimeAgo date={record?.last_online} />
                  ) : (
                    <TableEmpty />
                  );
                }}
                placement="topLeft"
              >
                <Tag color="error">Offline</Tag>
              </Tooltip>
            )}
          </>
        );
      },
    },

    {
      title: "Status",
      defaultFilteredValue: getAtSession("users")?.search?.status,
      filteredValue: getAtSession("users")?.search?.status,
      dataIndex: `status`,
      align: "center",
      key: "status",

      filters: statusOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,
      render: (text, record, index) => {
        if (record.archive) {
          return <Tag color="error">Archive</Tag>;
        }

        switch (record.status) {
          case 0:
          case 1:
            return <Tag color="success">Active</Tag>;

          case 2:
            return <Tag color="error">Disabled by tariff</Tag>;

          case 3:
            return <Tag color="error">Disabled by admin</Tag>;

          default:
            break;
        }
      },
    },

    {
      title: "Username",
      defaultFilteredValue: getAtSession("users")?.search?.username,
      filteredValue: getAtSession("users")?.search?.username,
      dataIndex: "username",
      key: "username",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "Name",
      defaultFilteredValue: getAtSession("users")?.search?.name,
      filteredValue: getAtSession("users")?.search?.name,
      dataIndex: "name",
      key: "name",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
      ...getColumnSearchProps(),
    },

    {
      title: "Surname",
      defaultFilteredValue: getAtSession("users")?.search?.surname,
      filteredValue: getAtSession("users")?.search?.surname,
      dataIndex: "surname",
      key: "surname",
      align: "center",
      sorter: true,

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
      ...getColumnSearchProps(),
    },

    {
      title: "Phone",
      defaultFilteredValue: getAtSession("users")?.search?.phone,
      filteredValue: getAtSession("users")?.search?.phone,
      dataIndex: "phone",
      key: "phone",
      align: "center",
      ...getColumnSearchProps(),
      sorter: true,
    },

    {
      title: "Email",
      defaultFilteredValue: getAtSession("users")?.search?.email,
      filteredValue: getAtSession("users")?.search?.email,
      dataIndex: "email",
      key: "email",
      align: "center",
      // ellipsis: {
      //   showTitle: false,
      // },
      sorter: true,

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },

      ...getColumnSearchProps(),
    },
    {
      title: "User Type",
      defaultFilteredValue: getAtSession("users")?.search?.is_guest,
      filteredValue: getAtSession("users")?.search?.is_guest,
      dataIndex: "is_guest",
      align: "center",
      key: "is_guest",
      filterMultiple: false,

      filters: userStatusOptions,
      onFilter: (value, record) => true,
      render: (text, record, index) => {
        if (record?.is_guest) {
          return <Tag color="warning">Guest</Tag>;
        } else {
          return <Tag color="success">Registered</Tag>;
        }
      },
    },

    {
      title: `Group`,
      defaultFilteredValue: getAtSession("users")?.search?.groupId,
      filteredValue: getAtSession("users")?.search?.groupId,
      dataIndex: "groupId",
      key: "groupId",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      filters: groupsOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={record?.group?.name}>
            {record.group ? record.group.name : "N/A"}
          </Tooltip>
        );
      },
    },

    {
      title: "Payment type",
      dataIndex: "payment_type",
      key: "payment_type",
      align: "center",
    },

    {
      title: "Subscription id",
      dataIndex: "subscription_id",
      key: "subscription_id",
      align: "center",

      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: `Registration date`,
      defaultFilteredValue: getAtSession("users")?.search?.register_date,
      filteredValue: getAtSession("users")?.search?.register_date,
      dataIndex: "register_date",
      key: "register_date",
      align: "center",

      render: (text, record, index) => {
        return parseDateFormat(text);
      },

      ...getColumnDateProps((date) => {
        setRegistrationDate(date);
        setExpireTariffDate(null);
        setTariffCreatedDate(null);
      }),
    },

    {
      title: `Tariff`,
      defaultFilteredValue: getAtSession("users")?.search?.tariffId,
      filteredValue: getAtSession("users")?.search?.tariffId,
      dataIndex: "tariffId",
      key: "tariffId",
      align: "center",

      filters: tariffOptions,
      filterMultiple: false,
      onFilter: (value, record) => true,

      render: (text, record, index) => {
        return <span>{record.tariff ? record.tariff.name : "N/A"}</span>;
      },
    },

    {
      title: `Tariff created date`,
      defaultFilteredValue: getAtSession("users")?.search?.upd_tariff,
      filteredValue: getAtSession("users")?.search?.upd_tariff,
      dataIndex: "upd_tariff",
      key: "upd_tariff",
      align: "center",

      render: (text, record, index) => {
        return text ? parseDateFormat(text) : "N/A";
      },

      ...getColumnDateProps((date) => {
        setRegistrationDate(null);
        setExpireTariffDate(null);
        setTariffCreatedDate(date);
      }),
    },

    {
      width: 130,
      sorter: true,
      defaultSortOrder:
        getAtSession("users")?.sort?.[0] === "auto_renewal" &&
        asc_desc(getAtSession("users")?.sort?.[1]),
      title: "Auto Renewal",
      key: "auto_renewal",
      dataIndex: "auto_renewal",
      align: "center",
      render: (text, record, index) => {
        return <Check value={record.auto_renewal} />;
      },
    },

    {
      title: "Watch",
      key: "sub_users",
      dataIndex: "sub_users",
      align: "center",
      render: (text, record, index) => {
        console.log("record", index);

        let channelName = "";

        if (record.sub_users.length && record.sub_users[0].channel) {
          channelName = record.sub_users[0].channel.name;
        }

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              alignItems: "baseline",
            }}
          >
            {/* {index.sub_users && index.sub_users[0] ? ( */}
            {
              record?.sub_users[0]?.isLiveWatching ? (
                <div
                  style={{
                    backgroundColor: "green",
                    margin: 0,
                    marginRight: 5,
                  }}
                  className="online-offline"
                />
              ) : (
                "N/A"
              )

              // <div
              //   style={{
              //     backgroundColor: "#dc3545",
              //     margin: 0,
              //     marginRight: 5,
              //   }}
              //   className="online-offline"
              // />

              // )
              // ) : (
              // "N/A"
              // )}
            }

            {channelName}
          </div>
        );
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "edit",
                name: "Edit",
                icon: icons.EDIT,
              },

              {
                key: "tariff",
                name: "Tariff",
                icon: icons.TARIFF,
              },

              record.status === 3
                ? {
                    key: "unlock",
                    name: "Unlock",
                    icon: icons.UNLOCK,
                    question: `Do you want to unlock this user`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  }
                : {
                    key: "lock",
                    name: "Lock",
                    icon: icons.LOCK,
                    question: `Do you want to lock this user`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  },

              record.archive
                ? {
                    key: "restore",
                    name: "Restore",
                    icon: icons.RESTORE,
                    question: `Do you want to restore this user`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  }
                : {
                    key: "archive",
                    name: "Archive",
                    icon: icons.ARCHIVE_ICON,
                    question: `Do you want to archive this user`,
                    onConfirm: (e) => {
                      handleMenuClick(e, record);
                    },
                  },
            ]}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    const newColumns = [...columns];

    if (permissions?.billing) {
      newColumns.splice(15, 0, {
        title: `Expiry date`,
        defaultFilteredValue: getAtSession("users")?.search?.expire_tariff,
        filteredValue: getAtSession("users")?.search?.expire_tariff,
        dataIndex: "expire_tariff",
        key: "expire_tariff",
        align: "center",
        render: (text, record, index) => {
          return text ? parseDateFormat(text) : "N/A";
        },

        ...getColumnDateProps((date) => {
          setRegistrationDate(null);
          setExpireTariffDate(date);
          setTariffCreatedDate(null);
        }),
      });
    }

    setTableColumns(newColumns);
  }, [permissions]);

  const handleTableChange = (data) => {
    const users = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      users.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    } else {
      users.sort = ["id", "DESC"];
    }
    setInLocal("pageLimit_users", data.limit);
    setInSession("users", users);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);
    setSearch(data.search);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleBukAction = (rowKey, action) => {
    const ids = rowKey?.join(",");

    handleMenuClick({ key: action }, { id: ids });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      }

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (search.surname) {
        query.search = {
          surname: search.surname[0],
        };
      }

      if (search.username) {
        query.search = {
          username: search.username[0],
        };
      }

      if (search.phone) {
        query.search = {
          phone: search.phone[0],
        };
      }

      if (search.email) {
        query.search = {
          email: search.email[0],
        };
      }

      if (search.groupId && search.groupId[0]) {
        if (query.filter) {
          query.filter = {
            ...query.filter,
            groupId: search.groupId[0],
          };
        } else {
          query.filter = {
            groupId: search.groupId[0],
          };
        }
      }

      if (search.tariffId && search.tariffId[0]) {
        if (query.filter) {
          query.filter = {
            ...query.filter,
            tariffId: search.tariffId[0],
          };
        } else {
          query.filter = {
            tariffId: search.tariffId[0],
          };
        }
      }

      if (search.status) {
        if (query.filter) {
          query.filter = {
            ...query.filter_in,
            status: search.status[0],
          };
        } else {
          query.filter_in = {
            status: search.status[0],
          };
        }
      }

      if (search.is_guest) {
        if (query.filter) {
          query.filter.is_guest = {
            ...query.filter,
            is_guest: search.is_guest[0],
          };
        } else {
          query.filter = {
            is_guest: search.is_guest[0],
          };
        }
      }

      const queryRegistrationDate = getQueryDate(registrationDate, "register_date");

      if (queryRegistrationDate) query.between = queryRegistrationDate;

      const queryTariffCreatedDate = getQueryDate(tariffCreatedDate, "upd_tariff");

      if (queryTariffCreatedDate) query.between = queryTariffCreatedDate;

      const queryExpireTariffDate = getQueryDate(expireTariffDate, "expire_tariff");

      if (queryExpireTariffDate) query.between = queryExpireTariffDate;

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);

        const hasSearchKeyWithValue = () => {
          const keys = Object.keys(search);

          return keys.some((key) => search[key] && search[key][0]);
        };

        const hasSortKeyWithValue = () => {
          return sort && sort[0];
        };

        if (data?.rows?.length > 0 || hasSearchKeyWithValue() || hasSortKeyWithValue()) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    limit,
    currentPage,
    sort,
    search,
    registrationDate,
    tariffCreatedDate,
    expireTariffDate,
    getAgain,
  ]);

  return (
    <TableComponent
      header={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: 20,
          }}
        >
          <Space>
            {permissions?.login_with_code && (
              <ButtonComponent
                icon={icons.LOGIN}
                title="Login"
                onClick={openLoginDrawer}
              />
            )}

            <ButtonComponent icon={icons.ADD} title="Add User" onClick={openDrawer} />

            {permissions?.bulk_user_creation && (
              <ButtonComponent
                title={"Add user bulk"}
                icon={icons.ADD}
                onClick={() => {
                  openMultiUserDrawer(true);
                }}
              />
            )}
          </Space>
          {selectedRowKeys.length > 0 && (
            <UserBulkAction
              handleAction={(action) => {
                handleBukAction(selectedRowKeys, action);
              }}
            />
          )}
        </div>
      }
      rowClassName="clickable-row"
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            navigate(PATHS.USER + record.id);
          },
        };
      }}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      rowSelection={rowSelection}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 2500,
      }}
    />
  );
}
