import { Drawer } from "antd";
import { memo, useEffect, useState } from "react";
import TableComponent from "../table/TableComponent";

export default function GpuDrawer({ open, onClose, gpu }) {
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    try {
      let process_info = [];
      if (gpu) {
        process_info = [
          {
            id: gpu.processes.process_info.pid,
            pid: gpu.processes.process_info.pid,
            type: gpu.processes.process_info.type,
            process_name: gpu.processes.process_info.process_name || "N/A",
            used_memory: gpu.processes.process_info.used_memory,
          },
        ];
      } else {
        process_info = [
          {
            id: "N/A",
            pid: "N/A",
            type: "N/A",
            process_name: "N/A",
            used_memory: "N/A",
          },
        ];
      }

      setDataSource(process_info);
    } catch (e) {
      console.log(e);
    }
  }, [gpu]);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: "Id",
      dataIndex: "pid",
      key: "pid",
      align: "center",
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      align: "center",
    },

    {
      title: "Name",
      dataIndex: "process_name",
      key: "process_name",
      align: "center",
    },

    {
      title: "Memory",
      dataIndex: "used_memory",
      key: "used_memory",
      align: "center",
    },
  ];

  return (
    <Drawer
      title={gpu?.product_name}
      placement="right"
      onClose={onClose}
      open={open}
      width={800}
    >
      <TableComponent dataSource={dataSource} columns={columns} />
    </Drawer>
  );
}
