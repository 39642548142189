import styles from "./style.module.scss";
// import ExclamationCircleOutlined from "anst"
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ButtonComponent from "../button/ButtonComponent";
import icons from "../../config/icons";
import { Button, Dropdown } from "antd";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router";
import PATHS from "../../config/paths";
import { setInLocal } from "../../helpers/storages";

const TableLocaleComponent = ({ click, actionExist, dropButton }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const parts = pathname.split("/");
  const lastPart = parts[parts.length - 1];

  const getLastWord = (name) => {
    const words = name.split(/[\s-/\\?&]+/);
    return words[words.length - 1];
  };

  const lastWord = getLastWord(lastPart);

  console.log("lastWord", lastWord);

  const handleClick = (e) => {
    if (!dropButton) {
      e.stopPropagation();
      e.preventDefault();
      click();
    }
  };

  const menuItems = [
    {
      key: "movie",
      label: "Add Movie",
      onClick: () => {
        navigate(PATHS.MEDIA_CONTENT_NEW);
        setInLocal("mediaType", "movie");
      },
    },
    {
      key: "tv_shows",
      label: "Add TV Show",
      onClick: () => {
        navigate(PATHS.MEDIA_CONTENT_NEW);
        setInLocal("mediaType", "tv_shows");
      },
    },
  ];

  return (
    <div className={styles["locale-component"]}>
      <div className={styles["local-component-icon"]}>
        <ExclamationCircleOutlined />
      </div>
      <div className={styles["local-component-head"]}>
        <span>You still don’t have {lastWord} </span>
      </div>
      <div className={styles["local-component-description"]}>
        <span>
          {actionExist
            ? "You still don't have any data here. Please hit add button to create it or watch tutorial if required."
            : "You don't have any information here. This information will be generated during the usage of the service."}
        </span>
      </div>

      <div className={styles["local-component-button"]}>
        {!dropButton ? (
          <ButtonComponent icon={icons.ADD} title={"Add "} onClick={handleClick} />
        ) : (
          <Dropdown menu={{ items: menuItems }}>
            <Button
              icon={icons.ADD}
              name="add"
              type="primary"
              style={{
                display: "flex",
                alignItems: "center",
                gap: 10,
                color: "white",
              }}
            >
              {"Add "}
            </Button>
          </Dropdown>
        )}
        <ButtonComponent icon={icons.PLAY} title={"Watch Tutorial  "} disabled={true} />
      </div>
    </div>
  );
};

export default TableLocaleComponent;
