import { Checkbox, Form, Radio, Select } from "antd";

import {
  useCountriesOptions,
  useAgeRestrictionOptions,
  usePaymentsSubscriptionsOptions,
  useAddListOPtions,
} from "../../../../hooks/selectOptions";

import NextBackButton from "../../../../_components/button/NextBackButton";

import confirmIsProtected from "./confirmIsProtected";

export default function RestrictionStep({
  form,
  setStep,
  devicesOptions,
  allDevicesIds,
  categoriesOption,
}) {
  const countriesOptions = useCountriesOptions();
  const ageRestrictionOptions = useAgeRestrictionOptions();
  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();
  const addListOptions = useAddListOPtions();

  const onSelectDevices = (selected) => {
    if (selected === "all") {
      form.setFields([{ name: "permission_devices", value: allDevicesIds }]);
    }
  };

  const onCheckGeolocationRestriction = (event) => {
    const checked = event.target.checked;

    if (!checked) {
      form.setFields([{ name: "available_countries", value: undefined }]);
    }
  };

  const onCheckIsProtected = (event) => {
    const checked = event.target.checked;

    confirmIsProtected({ form, categoriesOption, checked });
  };

  return (
    <div>
      <div style={{ display: "flex", gap: 40 }}>
        <Form.Item label="Age" name="age_restricted" style={{ width: "20%" }}>
          <Select style={{ width: "100%" }} options={ageRestrictionOptions} />
        </Form.Item>

        <Form.Item
          label="Content Monetization Type"
          name="content_monetization_type"
          style={{ width: "75%" }}
        >
          <Radio.Group buttonStyle="solid">
            <Radio value="free">Free</Radio>
            <Radio value="stv">STV</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <Form.Item shouldUpdate noStyle>
        {() => {
          const { content_monetization_type } = form.getFieldsValue();

          if (content_monetization_type === "stv") {
            return (
              <Form.Item label="Tariffs" name="tariffIds">
                <Select
                  options={paymentsSubscriptionsOptions}
                  mode="multiple"
                  style={{ width: "100%", marginTop: 5 }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) => {
                    return (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            );
          }
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { content_monetization_type } = form.getFieldsValue();

          if (content_monetization_type === "free") {
            return (
              <Form.Item label="Add" name="ads_id">
                <Select
                  options={addListOptions}
                  mode="multiple"
                  style={{ width: "100%", marginTop: 5 }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) => {
                    return (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            );
          }
        }}
      </Form.Item>

      <Form.Item
        label="Devices"
        name="permission_devices"
        rules={[
          {
            required: true,
            message: "Please select devices",
          },
        ]}
      >
        <Select mode="multiple" options={devicesOptions} onSelect={onSelectDevices} />
      </Form.Item>

      <div style={{ display: "flex", gap: 10, marginBottom: 15 }}>
        <Form.Item name="is_protected" noStyle valuePropName="checked">
          <Checkbox onChange={onCheckIsProtected}>Is protected</Checkbox>
        </Form.Item>

        <Form.Item name="is_location_restriction" noStyle valuePropName="checked">
          <Checkbox onChange={onCheckGeolocationRestriction}>
            Geolocation restriction
          </Checkbox>
        </Form.Item>
      </div>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { is_location_restriction } = form.getFieldsValue();
          return (
            <Form.Item
              label="Available countries"
              name="available_countries"
              rules={[
                {
                  required: is_location_restriction ? true : false,
                  message: "Please select countries",
                },
              ]}
            >
              <Select
                mode="multiple"
                options={countriesOptions}
                disabled={!is_location_restriction}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => setStep("2")} />
        <NextBackButton type="next" onClick={() => setStep("4")} />
      </div>
    </div>
  );
}
