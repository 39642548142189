import { Badge, Space } from "antd";
import styles from "./_server.module.scss";

const showTime = (time) => {
  let hour = parseInt(time / 60 / 60);
  let day = hour / 24;
  let _hour = hour % 24;

  return (
    <p>
      Uptime:{" "}
      <span>
        {parseInt(day)} day {parseInt(_hour)} hour
      </span>
    </p>
  );
};

export default function Footer({ data, status }) {
  return (
    <div className={styles["footer"]}>
      <div>
        <p>
          Input: <span>{data?.inputMBS} MBS</span>
        </p>

        <p>
          Output: <span>{data.outputMBS} MBS</span>
        </p>
      </div>

      <div>
        <div>{showTime(data.uptime)}</div>

        <div className={styles["connect"]}>
          <p style={{ color: status == 1 ? "#75a99c" : "#ff4d4f", fontWeight: "bold" }}>
            {status == 1 ? (
              <Space>
                Connected
                <Badge status="processing" color="#75a99c" />
                <Badge status="processing" color="#75a99c" />
                <Badge status="processing" color="#75a99c" />
              </Space>
            ) : (
              <Space>
                Disconnected
                <Badge status="error" />
                <Badge status="error" />
                <Badge status="error" />
              </Space>
            )}
          </p>
        </div>
      </div>
    </div>
  );
}
